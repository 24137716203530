import {Layout} from '@components/Layout'
import {AllBlogPosts} from '@components/LegacyComponents/Blog/AllBlogPosts'
import {IBlogPage, IPageProps} from '@customTypes/types'
import {filterDataToSingleItem} from '@helpers/filterDataToSingleItem'
import {useLivePreview} from '@hooks/useLivePreview'
import {GetStaticProps, NextPage} from 'next'
import {initialBlogPostsCount} from 'src/constants/blogPosts'
import {getStaticPage} from 'src/data'
import {blogArchiveQuery} from 'src/data/queries/pageQueries'
import {st} from 'src/locales/TranslationHelper'

const BlogPage: NextPage<IPageProps<IBlogPage>> = ({pageData, preview, queryParams, query}) => {
  const {data} = useLivePreview(query, {initialData: pageData, params: queryParams, enabled: preview})

  return (
    <Layout
      header={data.site.header}
      footer={data.site.footer}
      announcement={data.site.announcement}
      defaultSeo={data.site.defaultSeoSettings}
      pageMetaTitle={st.blogArchive.pageMetaTitle()}
      isPreview={preview}
    >
      <AllBlogPosts blogPageData={data.page} isPreview={preview} />
    </Layout>
  )
}

export default BlogPage

export const getStaticProps: GetStaticProps = async ({locale, preview = false}) => {
  const queryParams = {locale}
  const {data, query} = await getStaticPage<IBlogPage>(blogArchiveQuery(initialBlogPostsCount), queryParams, preview)

  // Show 404 page if no page found
  if (!data) {
    return {notFound: true}
  }

  // Helper function to get most recent document from array of documents
  const filteredPage = {...data, page: filterDataToSingleItem(data.page, preview)}

  return {
    props: {
      pageData: filteredPage,
      query: preview ? query : null,
      queryParams,
      preview,
    },
  }
}
